import  LoanRequest  from "./LoanRequest";

export class ApiProps{
    request?: LoanRequest;
    apiUrl?: string;
    routePath?:string;
    viewName?:string;
    viewDescription:string;
    backgroundColor:string;
    iconImage:string;

    constructor(req:LoanRequest, apiUrl: string,
        routePath: string, viewName:string, 
        viewDescription:string, backgroundColor:string,
        iconImage: string
        )        
        {
        this.request = req;
        this.apiUrl = apiUrl;
        this.routePath = routePath;
        this.viewName = viewName;
        this.viewDescription= viewDescription;
        this.backgroundColor=backgroundColor;
        this.iconImage = iconImage;
    }

   createObject(obj:any):ApiProps{
    const stringifyViewData = JSON.stringify(obj);
    const apiProps = JSON.parse(stringifyViewData);
    this.apiUrl= apiProps.apiUrl;
    this.routePath= apiProps.routePath;
    this.viewName=apiProps.viewName;
    this.viewDescription=apiProps.viewDescription;
    this.backgroundColor=apiProps.backgroundColor;
    this.iconImage = apiProps.iconImage;
    
   
    this.request = this.createRequest(apiProps.request);
    return this;
   }

   createRequest(obj:any):LoanRequest{
    const stringifyRequest = JSON.stringify(obj);    
    const reqObject = JSON.parse(stringifyRequest);
    return reqObject;
   }
}