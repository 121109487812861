import React, { useState } from "react";
import logo from "./images/png12.png";
import LoanRequest from './models/LoanRequest';
import CardComponent from "./cards/CardComponent";
import { APIConstants, RouteConstants } from "./constants/AppConstants";
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "./auth/AuthContext"
import { Link, useHistory } from "react-router-dom"
function Dashboard() {

  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()


  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }


  const apiPropsList = [
    {
      "allLoans":
      {
        "request": new LoanRequest("", "Active"),
        "apiUrl": APIConstants.LOANS_HIST_URL,
        "routePath": RouteConstants.LOAD_ACTIVE_LOANS,
        "viewName": "Active Loans",
        "viewDescription": "These are the current active loans to be paid off.",
        "backgroundColor": "#FF7830",
        //"iconImage":"M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" 
        "iconImage": "M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
      },
      "allNewTrxs":
      {
        "request": new LoanRequest("", "New"),
        "apiUrl": APIConstants.LOANS_HIST_URL,
        "routePath": RouteConstants.LOAD_NEW_TRXS,
        "viewName": "New Transactions",
        "viewDescription": "These are the new transactions representing new loans to go through approval process.",
        "backgroundColor": "#D8A878",
        "iconImage": 'M3 13h2v-2H3v2zm0 4h2v-2H3v2zm2 4v-2H3c0 1.1.89 2 2 2zM3 9h2V7H3v2zm12 12h2v-2h-2v2zm4-18H9c-1.11 0-2 .9-2 2v10c0 1.1.89 2 2 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12H9V5h10v10zm-8 6h2v-2h-2v2zm-4 0h2v-2H7v2z'
      },
      "allApprovedTrxs":
      {
        "request": new LoanRequest("", "Approved"),
        "apiUrl": APIConstants.LOANS_HIST_URL,
        "routePath": RouteConstants.LOAD_APPROVED_TRXS,
        "viewName": "Approved Transactions",
        "viewDescription": "These are the transactions that have been approved and automatically become paid/unpaid loans.",
        "backgroundColor": "#18A890",
        "iconImage": 'M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'
      },
      "pendingTrxs":
      {
        "request": new LoanRequest("", "Pending"),
        "apiUrl": APIConstants.LOANS_HIST_URL,
        "routePath": RouteConstants.LOAD_PENDING_TRXS,
        "viewName": "Pending Transactions",
        "viewDescription": "These are the transactions that are under investigation or approving process.",
        "backgroundColor": "#C0A0C0",
        "iconImage": 'M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13s-.866-1.299-3-1.48V8.35z'
      },
      "duedLoans":
      {
        "request": new LoanRequest("", "Dued"),
        "apiUrl": APIConstants.LOANS_HIST_URL,
        "routePath": RouteConstants.LOAD_DUED_LOANS,
        "viewName": "Expired Loans",
        "viewDescription": "These are the loans that have exceeded due data, immediate action is required.",
        "backgroundColor": "#C0C0C0",
        "iconImage": 'M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13s-.866-1.299-3-1.48V8.35z'
      },
      "rejectedLoans":
      {
        "request": new LoanRequest("", "Rejected"),
        "apiUrl": APIConstants.LOANS_HIST_URL,
        "routePath": RouteConstants.LOAD_REJECTED_LOANS,
        "viewName": "Rejected Transactions",
        "viewDescription": "These are the transactions rejected due to some false profiles or other issues.",
        "backgroundColor": "crimson",
        "iconImage": 'M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13s-.866-1.299-3-1.48V8.35z'
      },
      "profileData":
      {
        "request": new LoanRequest("", "Profile"),
        "apiUrl": APIConstants.PROFILE_DATA_URL,
        "routePath": RouteConstants.PROFILE_DATA_URL,
        "viewName": "User Profiles",
        "viewDescription": "These are the total number of users on the platform",
        "backgroundColor": "orange",
        "iconImage": 'M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13s-.866-1.299-3-1.48V8.35z'
      }
    }
  ];


  return (
    <div>
      <nav className="navbar navbar-light bg-tranparent">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo"
              height="170"
              style={{ marginTop: "-60px", marginBottom: "-60px" }}
            />
          </a>
          <Card>
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              <strong>{currentUser.email}</strong>
              {/* <Link to="/update-profile" className="btn btn-danger w-100 mt-3">
            Update Profile
          </Link> */}
              <Button variant="link" onClick={handleLogout}>
                Log Out
        </Button>
            </Card.Body>
          </Card>
        </div>
      </nav>
      <h3 className="text-center">Brokers Portal</h3>
      <div className="container mt-5">
        <div className="row">

          <div className="col-sm-6 col-md-4 mb-3">
            <CardComponent viewData={apiPropsList[0].allNewTrxs} />
          </div>

          <div className="col-sm-6 col-md-4 mb-3">
            <CardComponent viewData={apiPropsList[0].allLoans} />
          </div>

          <div className="col-sm-6 col-md-4 mb-3">
            <CardComponent viewData={apiPropsList[0].allApprovedTrxs} />
          </div>
          <div className="col-sm-6 col-md-4 mb-3">
            <CardComponent viewData={apiPropsList[0].pendingTrxs} />
          </div>
          <div className="col-sm-6 col-md-4 mb-3">
            <CardComponent viewData={apiPropsList[0].duedLoans} />
          </div>
          <div className="col-sm-6 col-md-4 mb-3">
            <CardComponent viewData={apiPropsList[0].rejectedLoans} />
          </div>
          {/* <div className="col-sm-6 col-md-4 mb-3">
          <CardComponent viewData={apiPropsList[0].profileData} />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Dashboard 
