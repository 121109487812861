import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, Row, Col, Image } from "react-bootstrap";
import { useAuth } from "./AuthContext";
import { Link, useHistory } from "react-router-dom";
import info from "../images/brokers.png";
import logo from "../images/png12.png";


export default function UpdateProfile() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    const promises = []
    setLoading(true)
    setError("")

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises)
      .then(() => {
        history.push("/")
      })
      .catch(() => {
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <nav className="navbar navbar-light bg-transparent">
        <div className="container">
          <a className="navbar-brand" href>
            <img src={logo} alt="logo"
              height="170"
              style={{ marginTop: "-60px", marginBottom: "-60px" }}
            />
          </a>
        </div>
      </nav>
      <Container>
        <Row>
          <Col sm={6}>
            <Image src={info} fluid />
          </Col>
          <Col className="mt-5" sm={6}>
            <Container
            // className="d-flex align-items-center justify-content-center"
            // style={{ minHeight: "100vh" }}
            >
              <div className="w-100" style={{ maxWidth: "400px" }}>
                <Card>
                  <Card.Body>
                    <h2 className="text-center mb-4">Update Profile</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                      <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          ref={emailRef}
                          required
                          defaultValue={currentUser.email}
                        />
                      </Form.Group>
                      <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          ref={passwordRef}
                          placeholder="Leave blank to keep the same"
                        />
                      </Form.Group>
                      <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control
                          type="password"
                          ref={passwordConfirmRef}
                          placeholder="Leave blank to keep the same"
                        />
                      </Form.Group>
                      <Button disabled={loading} className="w-100 mt-3" variant="danger" type="submit">
                        Update
            </Button>
                    </Form>
                  </Card.Body>
                </Card>
                <div className="w-100 text-center mt-2">
                  <Link to="/">Cancel</Link>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}
