export enum DataConstants {
    NEW_TRX_DATA = "NEW_TRX_DATA",
    PENDING_TRX_DATA = "PENDING_TRX_DATA",
    APPROVED_TRX_DATA = "APPROVED_TRX_DATA",
    CANCELLED_TRX_DATA = "CANCELLED_TRX_DATA",
    REJECTED_TRX_DATA = "REJECTED_TRX_DATA",
    ACTIVE_TRX_DATA = "ACTIVE_TRX_DATA",
    DUED_LOAN_DATA = "DUED_TRX_DATA",
}

export enum TypeConstants {
    New = "New",
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected",
    Cancelled = "Cancelled",
    Removed = "Rejected",
    Active = "Active",
    Dued = "Dued"
}
export abstract class HttpConstants {
    public static readonly POST = 'POST';
}

export abstract class APIConstants {
    public static readonly BROKER_URL = "https://api.aipalpay.com/broker";
    public static readonly BROKER_PAYOFF_URL = "https://api.aipalpay.com/broker/payoff";
    public static readonly LOANS_HIST_URL = "https://api.aipalpay.com/loans";
    public static readonly PROFILE_DATA_URL = "https://api.aipalpay.com/profile";
}

export abstract class RouteConstants {
    public static readonly LOAD_NEW_TRXS = '/newtrxs';
    public static readonly LOAD_ACTIVE_LOANS = '/activeloans';
    public static readonly LOAD_APPROVED_TRXS = '/approvedtrxs';
    public static readonly LOAD_PENDING_TRXS = '/pendingtrxs';
    public static readonly CUSTOM_DIALOG = '/dialog';
    public static readonly LOAD_DUED_LOANS = '/duedloans';
    public static readonly LOAD_REJECTED_LOANS = '/rejectedtrxs';
    public static readonly HOME = '/';
    public static readonly LOAD_PROFILE_DATA = '/profiles';
}

export abstract class TrxTypeConstants {
    public static readonly TRXS_NEW = 'trxnew';
    public static readonly TRXS_HIST = 'trxhist';
    public static readonly TRXS_APPROVED = 'trxapproved';
    public static readonly TRXS_REJECTED = 'trxrejected';
    public static readonly TRXS_CANCELLED = 'trxcancelled';
    public static readonly TRXS_INPROGRESS = 'trxinprogress';
    public static readonly TRXS_PENDING = 'trxpending';
    public static readonly ACTIVE_LOANS = 'loans';


}