import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import logo from "../images/png12.png";
import LeakAddIcon from "@material-ui/icons/LeakAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
//import ProgressBar from "./ProgressBar";
import { TrxService } from "../services/TrxService";
import {
  APIConstants,
  TrxTypeConstants,
  TypeConstants,
} from "../constants/AppConstants";
import CachedIcon from "@material-ui/icons/Cached";
import CustomDialog from "./CustomDialog";

function RejectedTrxsView(props) {
  const svc = new TrxService();

  const [open, setOpen] = useState(false);

  let valCount;
  let enabledCount = 0;

  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  //const [progress, setProgress] = useState(10);
  //const [showProgressBar, setShowProgressBar] = useState(false);
  //const [stop, setStop] = useState(false);
  const [trxDetails, setTrxDetails] = useState(null);
  const [openCustomDialog, isOpenCustomDialog] = useState(false);

  useEffect(() => {
    console.log("Rejected!");
    const localData = svc.getFromLocalStorage(
      svc.getAppropriateType(TypeConstants.Rejected)
    );
    try {
      setData(localData);
    } catch (e) {
      console.log(e);
    }
  }, []);

  let count = 1;

  function handleChange(e, index) {
    const value = e.target.value;
    console.log("VALUE: " + value);
    const values = { ...selectedValues };
    values[index] = value;
    setSelectedValues(values);

    console.log(JSON.stringify(selectedValues).length);
  }

  async function handleClickProcess(e) {
    // e.preventDefault();
    //CALL API to POST DATA

    const request = {
      trnId: selectedValues,
      brokerAid: "fromBrokerPortalID",
      type: TrxTypeConstants.TRXS_REJECTED,
    };

    const response = await svc.processTrxs(request, APIConstants.BROKER_URL);

    svc.addToLocalStorage(
      svc.getAppropriateType(TypeConstants.Rejected),
      response
    );

    setData(response);
    setOpen(false);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefreshClick = () => {
    refresh();
  };
  function refresh() {
    const callBackReq = {
      type: TypeConstants.Rejected.toString(),
    };
    console.log(callBackReq + " callbackreq" + APIConstants.LOANS_HIST_URL);
    svc.refreshData(callBackReq, APIConstants.LOANS_HIST_URL);

    const localData = svc.getFromLocalStorage(
      svc.getAppropriateType(callBackReq.type)
    );
    try {
      setData(localData);
    } catch (e) {
      console.log("refresh failed");
    }
  }
  const enable = () => {
    const stringValues = JSON.stringify(selectedValues);
    if (stringValues === "[]") {
      console.log("EMPTY");
      valCount = 0;
    } else {
      let valArray = stringValues.split(",");

      valCount = valArray.length;
      console.log("ValArray: " + valArray);
      valArray.map((obj) => {
        let objString = JSON.stringify(obj);
        console.log("map item " + objString);
        if (objString.includes("Removed")) ++enabledCount;
      });
    }
    console.log("valCount = " + valCount + ", removedCount = " + enabledCount);
  };

  enable();

  //this is to open each trx details modal dialog
  const handleCustomDialogOpen = async (e, trnId) => {
    console.log("Trx Id: " + trnId);
    isOpenCustomDialog(true);
    //call API to fetch all data needed
    const request = {
      trxId: trnId,
      brokerAid: "fromBrokerPortalID",
      type: "Pending",
    };

    let localData = svc.getFromLocalStorage(trnId);
    if (localData == null) {
      console.log("making a call to get trx details");
      const response = await svc.getUserTrxDetails(
        request,
        APIConstants.LOANS_HIST_URL
      );
      svc.addToLocalStorage(trnId, response);
      setTrxDetails(response);
    } else {
      console.log("using local storage");
      setTrxDetails(localData);
    }
  };

  const handleCustomDialogClose = (value) => {
    isOpenCustomDialog(false);
    //setTrxDetails(value);
  };

  return (
    <div>
      <nav className="navbar navbar-light bg-transparent">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="logo"
              height="170"
              style={{ marginTop: "-60px", marginBottom: "-60px" }}
            />
          </a>
          {/* {showProgressBar ? (
            <ProgressBar show={showProgressBar} value={progress} stop={stop} />
          ) : (
            <div></div>
          )} */}
          <form className="d-flex mt-3">
            <input
              className="form-control mr-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-warning" type="submit">
              Search
            </button>
          </form>
        </div>
      </nav>
      <div className="container mt-5">
        <h2>Rejected Transactions</h2>
        <br />
        <CachedIcon
          onClick={handleRefreshClick}
          fontSize="inherit"
          style={{ fontSize: "40px" }}
        />

        <div style={{ marginLeft: "90%" }}>
          <Button
            id="processButton"
            variant="contained"
            color="secondary"
            startIcon={<LeakAddIcon />}
            onClick={handleClickOpen}
            disabled={
              valCount === 0 || valCount === enabledCount ? true : false
            }
          >
            Process
          </Button>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Transaction Id</th>
              <th scope="col">Amount</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{count++}</td>
                    <td>
                      <a onClick={(e) => handleCustomDialogOpen(e, item.trnId)}>
                        {item.trnId}
                      </a>
                    </td>
                    <td>{item.amount}</td>
                    <td>
                      <select
                        className="form-select form-select-sm"
                        aria-label="Default select example"
                        value={selectedValues[index] || null}
                        onChange={(e) =>
                          handleChange(e, item.trnId + ":" + item.status)
                        }
                      >
                        <option value="Removed">....</option>
                        <option value="Reviewed">Re-Evaluate</option>
                        <option value="Cancelled">Cancel</option>
                      </select>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Process the selected transactions?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Have you fully reviewed all the actions you selected for all the
            transactions?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Need More time
          </Button>
          <Button onClick={handleClickProcess} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <CustomDialog
        open={openCustomDialog}
        onClose={handleCustomDialogClose}
        data={trxDetails}
      ></CustomDialog>
    </div>
  );
}

export default RejectedTrxsView;
