import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Switch, Route } from "react-router-dom";

import Dashboard from './Dashboard';

import ActiveLoansView from './views/ActiveLoansView';
import NewTrxsView from './views/NewTrxsView';
import React from 'react';
import ApprovedTrxsView from './views/ApprovedTrxsView';
import PendingTrxsView from './views/PendingTrxsView';
import { RouteConstants } from './constants/AppConstants';
import CustomDialog from './views/CustomDialog';
import DuedLoansView from './views/DuedLoansView';
//import AlertView from './views/AlertView';
import RejectedTrxsView from './views/RejectedTrxsView';
import ProfileView from './views/ProfileView';
import Login from "./auth/Login";
import PrivateRoute from "./auth/PrivateRoute";
import ForgotPassword from "./auth/ForgotPassword";
import Signup from "./auth/Signup";
import { AuthProvider } from "./auth/AuthContext";
import UpdateProfile from './auth/UpdateProfile';
;
function App() {
  return (
    <div>
      <AuthProvider>
        <Switch>
          {/* <Route exact path={RouteConstants.HOME} component={Dashboard} /> */}
          <PrivateRoute exact path={RouteConstants.HOME} component={Dashboard} />
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route exact path='/updateprofile' component={UpdateProfile} />
          <Route exact path={RouteConstants.LOAD_ACTIVE_LOANS} render={props => <ActiveLoansView {...props} />} />
          <Route exact path={RouteConstants.LOAD_NEW_TRXS} render={props => <NewTrxsView {...props} />} />
          <Route exact path={RouteConstants.LOAD_APPROVED_TRXS} render={props => <ApprovedTrxsView {...props} />} />
          <Route exact path={RouteConstants.LOAD_PENDING_TRXS} render={props => <PendingTrxsView {...props} />} />
          <Route exact path={RouteConstants.LOAD_DUED_LOANS} render={props => <DuedLoansView {...props} />} />
          <Route exact path={RouteConstants.LOAD_REJECTED_LOANS} render={props => <RejectedTrxsView {...props} />} />
          <Route exact path={RouteConstants.CUSTOM_DIALOG} render={props => <CustomDialog {...props} />} />
          <Route exact path={RouteConstants.LOAD_PROFILE_DATA} render={props => <ProfileView {...props} />} />
        </Switch>
      </AuthProvider>
    </div>

  );
}

export default App;
