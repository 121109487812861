import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DetailsView from "./DetailsView";
import Paper from "@material-ui/core/Paper";

export default function CustomDialog(props) {
  const { onClose, selectedValue, open, data } = props;
  console.log(data);
  const handleClose = () => {
    onClose(selectedValue);
  };

  return data != null ? (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {" "}
      <Paper elevation={3}>
        <DialogTitle id="simple-dialog-title">
          {data != null ? data.trnId : ""}
          <h6>
            {data != null && data.aipalScore > 300 ? (
              <div style={{ color: "green" }}>
                <i>{data.intelligence}</i>
              </div>
            ) : (
              <div style={{ color: "red" }}>{data.intelligence} </div>
            )}
          </h6>
        </DialogTitle>
        <DetailsView data={data} />
      </Paper>
    </Dialog>
  ) : (
    <div></div>
  );
}

// CustomDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };

// export default function CustomDialog(props) {
//   const [open, setOpen] = React.useState(false);
//   const [selectedValue, setSelectedValue] = React.useState(emails[1]);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = (value) => {
//     setOpen(false);
//     setSelectedValue(value);
//   };

//   return (
//     <div>
//       <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
//       <br />
//       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//         Open simple dialog
//       </Button>
//       <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
//     </div>
//   );
// }
