import React, { useState, useEffect } from "react";
import { TrxService } from "../services/TrxService";
import { Link } from "react-router-dom";
import { ApiProps } from "../models/ApiProps";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CardComponent(props) {
  const svc = new TrxService();

  const { viewData } = props;
  //create ApiProps object
  const apiPropsInstance = new ApiProps();
  const apiProps = apiPropsInstance.createObject(viewData);

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);

  const [request, setRequest] = useState(apiProps.request);

  const styleMe = {
    background: apiProps.backgroundColor,
    height: "18rem",
  };

  const apiURL = apiProps.apiUrl;

  useEffect(() => {
    console.log("Current Card: " + request.type);
    onLoad();
  }, [request, apiURL]);

  const onLoad = async () => {
    const response = await svc.fetchTransactions(request, apiURL);
    svc.addToLocalStorage(svc.getAppropriateType(request.type), response);
    const parsedData = svc.getFromLocalStorage(
      svc.getAppropriateType(request.type)
    );
    try {
      const counting = response != null ? Object.keys(response).length : 0;
      setCount(counting);
      setData(parsedData);
      console.log(request.type + ": " + counting);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="card mb-3" style={styleMe}>
      <div className="card-content">
        <div className="card-body">
          <div className="media d-flex">
            <div className="align-self-center">
              <SvgIcon {...props} style={{ fontSize: 80, color: "white" }}>
                <path d={apiProps.iconImage} />
              </SvgIcon>
              <div className="text-right">
                <h5 className="card-title tc">{apiProps.viewName}</h5>
                <h3 className="tc">
                  {" "}
                  <Link
                    to={{
                      pathname: apiProps.routePath,
                      state: {
                        items: data,
                      },
                    }}
                  >
                    {" "}
                    {count}{" "}
                  </Link>
                </h3>
                <p className="card-text tc">{apiProps.viewDescription}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
