import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tdColor: {
    backgroundColor: "#F5EFED",
  },
  scrollBar: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.4em",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        outline: "1px solid slategrey",
      },
    },
  },
}));

export default function DetailsView(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { data } = props;
  const { loanHistory } = data;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            <b>Transaction</b>
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Click here to review the transaction information.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table cellPadding="10px">
            <tr>
              <td className={classes.tdColor}>Signed by: </td>
              <td>{data.trxDetail && data.trxDetail.signatureName}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Amount: </td>
              <td>{data.trxDetail.amount}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}> Created Date: </td>
              <td>{data.trxDetail.dateTime}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Interest Rate: </td>
              <td>{data.trxDetail.interestRate}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Service Rate: </td>
              <td>{data.trxDetail.serviceRate}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Last 4 digit of BVN: </td>
              <td>{data.trxDetail.interestRate}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Previous Status: </td>
              <td>{data.trxDetail.prevStatus}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Current Status: </td>
              <td>{data.trxDetail.status}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Verified Requestor: </td>
              <td>{data.trxDetail.verifiedRequestor}</td>
            </tr>
          </table>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>
            <b>Profile</b>
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Click here to view user information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table className="table">
            <tr>
              <td className={classes.tdColor}>FirstName: </td>
              <td>{data.profile.firstName}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>SurName: </td>
              <td>{data.profile.lastName}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>OtherName: </td>
              <td>{data.profile.lastName}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Address: </td>
              <td>{data.profile.address}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Registered Phone: </td>
              <td>{data.profile.phoneNumber}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Last 4 BVN: </td>
              <td>{data.profile.last4BVN}</td>
            </tr>
            <tr>
              <td className={classes.tdColor}>Last 4 NIN: </td>
              <td>{data.profile.last4NIN}</td>
            </tr>
          </table>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>
            <b>Loan History</b>
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Click here to see all the loans being paid off.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.scrollBar}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Transaction Id</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Payoff Date</th>
                  <th scope="col">Aipalpay Score</th>
                </tr>
              </thead>
              <tbody>
                {/* {items && items.map(renderPost)} */}

                {loanHistory &&
                  loanHistory.map((item, index) => {
                    return (
                      <tr>
                        <td>{++index}</td>

                        <td>{item.trnId}</td>
                        <td>{item.amount}</td>
                        <td>{item.dueDate}</td>
                        <td>{item.paidOfDate}</td>
                        <td>{item.aipalPayScore}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>
            <b>Documents</b>
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Check all the submitted documents
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <table className="table">
            <tr>
              <td>
                <Link to="/">Photo ID</Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/">Driver Licence ID</Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/">International Passport</Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/">Office/School ID</Link>
              </td>
            </tr>
          </table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
