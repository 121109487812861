
import axios from "axios";
import LoanRequest from '../models/LoanRequest';
import { TrxModel } from "../models/TrxModel";
import { DataConstants, TypeConstants, HttpConstants } from "../constants/AppConstants";
import { TrxDetailModel } from "../models/TrxDetailModel";


export class TrxService {

    count: number = 0;


    async getData(request: LoanRequest) {
        console.log("getData" + request);
        await axios
            .post(
                "https://api.aipalpay.com/loans", request
            )
            .then(response => {
                console.log("response===> " + response);
                const { body } = response.data;
                console.log("data===> " + body);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getTrx(request: TrxModel, apiURL: string) {

        console.log("req:" + request.type)
        const response =
            await axios
                .post(
                    apiURL, request
                );


        const { body } = await response.data;

        console.log("getTrx:BODY");
        console.log(body);

    }

    getCount(): number {
        return this.count;
    }

    async fetchTransactions(request: LoanRequest, apiURL: string): Promise<any> {
        const req = JSON.stringify(request);
        console.log(req);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: req
        };
        const response = await fetch(apiURL, requestOptions);
        const json = await response.json();

        //console.log( " RAW:" + json.body)
        try {
            const data = json.body;
            return data ? JSON.parse(data) : null;
        } catch (e) {
            console.log("unable to parse - fetch trx");
        }

        return null;
    }

    getAppropriateType(type: string) {
        if (type === TypeConstants.New.toString()) {
            return DataConstants.NEW_TRX_DATA.toString();
        } else if (type === TypeConstants.Pending.toString()) {
            return DataConstants.PENDING_TRX_DATA.toString();
        } else if (type === TypeConstants.Approved.toString()) {
            return DataConstants.APPROVED_TRX_DATA.toString();
        } else if (type === TypeConstants.Rejected.toString()) {
            return DataConstants.REJECTED_TRX_DATA.toString();
        } else if (type === TypeConstants.Active.toString()) {
            return DataConstants.ACTIVE_TRX_DATA.toString();
        } else if (type === TypeConstants.Dued.toString()) {
            return DataConstants.DUED_LOAN_DATA.toString();
        }
    }

    async processTrxs(request: TrxModel, apiURL: string): Promise<any> {
        const trnId = JSON.stringify(request.trnId).replace(/"/g, '');

        let trnIdTrim = trnId.replace('{', '');
        request.trnId = trnIdTrim.replace('}', '');

        const req = JSON.stringify(request);

        console.log("Request to be processed:" + req);
        const requestOptions = {
            method: HttpConstants.POST,
            headers: { 'Content-Type': 'application/json' },
            body: req
        };
        const response = await fetch(apiURL, requestOptions);
        const json = await response.json();
        const data = json.body;
        const parsedData = JSON.parse(data);

        return parsedData;


    }
    async refreshData(request: LoanRequest, apiURL: string) {
        console.log("data refresh starts for: " + request.type);
        const response = await this.fetchTransactions(request, apiURL);
        this.addToLocalStorage(this.getAppropriateType(request.type), response);
        console.log("completed data refreshing for: " + request.type);
    }


    async getUserTrxDetails(request: TrxModel, apiURL: string): Promise<TrxDetailModel> {

        const req = JSON.stringify(request);

        console.log("Request to be processed:" + req);
        const requestOptions = {
            method: HttpConstants.POST,
            headers: { 'Content-Type': 'application/json' },
            body: req
        };

        const response = await fetch(apiURL, requestOptions);
        const json = await response.json();
        const data = json.body;
        console.log(data);

        const parsedData = data != undefined ? JSON.parse(data) : null;
        return parsedData;
    }

    addToLocalStorage(key: any, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    getFromLocalStorage(key: any): any {

        try {
            const data = localStorage.getItem(key);
            return data ? JSON.parse(data) : null;
        } catch (e) {
            console.log("Unable to parse date");
        }
        //const parseData = JSON.stringify(data);
        // console.log(parseData);
        return null;
    }

}


