import React, { useState, useEffect } from "react";
import logo from "../images/png12.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  APIConstants,
  TrxTypeConstants,
  TypeConstants,
} from "../constants/AppConstants";
import { TrxService } from "../services/TrxService";
const columns = [
  {
    dataField: "trnId",
    text: "Transaction Id",
    sort: true,
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
  },
  {
    dataField: "prevStatus",
    text: "Prev. Status",
    sort: true,
  },
  {
    dataField: "dueDate",
    text: "Due Date",
    sort: true,
  },
  {
    dataField: "broker",
    text: "Broker",
    sort: true,
  },
];
const svc = new TrxService();

function ApprovedTrxsView(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const localData = svc.getFromLocalStorage(
      svc.getAppropriateType(TypeConstants.Approved)
    );
    try {
      setData(localData);
    } catch (e) {
      console.log(e);
    }
  }, []);

  let count = 1;
  return (
    <div>
      <nav className="navbar navbar-light bg-transparent">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="logo"
              height="170"
              style={{ marginTop: "-60px", marginBottom: "-60px" }}
            />
          </a>

          <form className="d-flex mt-3">
            <input
              className="form-control mr-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-warning" type="submit">
              Search
            </button>
          </form>
        </div>
      </nav>
      <div style={{ padding: "5%" }}>
        <h2>Approved Loans</h2>
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          striped
          hover
          condensed
          pagination={paginationFactory()}
        />
      </div>
    </div>
  );
}

export default ApprovedTrxsView;
