export default class LoanRequest{
    aid?: string;
    type: string;

    
    constructor(aid:string, type:string){
        this.aid= aid;
        this.type=type;
      //  console.log("constructor: " + aid + " " + type);
    }
    
}